<template>
    <div class="container-fluid pt-4 px-1 px-md-3">

        <mdb-row class="gy-3">

            <mdb-col col="12" class="order-3">
                <h3 class="spainhn-subtitle text-uppercase">{{ $t("default.HOME_NUESTROS_MONUMENTOS") }}</h3>
                <!-- MONUMENTOS -->
                <mdb-container class="px-0 px-md-2" fluid>

                    <div v-if="castillos?.length > 0" class="mt-5">
                        <hr class="m-0 mt-1 mx-3">
                        <mdb-badge class="spainhn-header-info text-uppercase"
                            style="background-color: var(--spainhn-rojo-spainhn) !important;">
                            {{ !sugerencias ? $t("default.HOME_RESULTS_NEAR_CASTLES_LABEL") :
                                $t("default.HOME_RESULTS_CASTLES_LABEL") }}
                            <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                icon="fa fa-location-dot" />
                        </mdb-badge>
                        <div class="d-md-block d-none mb-4" v-if="castillos.length > 0">
                            <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap" v-if="!clorian">

                                <Cards2 v-for="(castillo, index) in castillos" :key="index"
                                    :monument="castillo.webNombres"
                                    :provincia="castillo.provincia" :municipio="castillo.municipio"
                                    :imageUrl="`${imagenesUrl}/monumentos/${castillo.oldId}/${castillo.banner}`"
                                    :id="castillo._id"
                                    :nombreUrl="castillo.nombres?.find(item => item.idioma === $i18n.locale)?.texto"
                                    :text="castillo.descripciones?.[selectedIdiomaIndex]?.texto" role="button" />

                            </div>
                        </div>
                        <mdb-row class="mx-auto d-md-none" v-bind:class="{ 'card-container': !showAllCastles }">

                            <mdb-col class="px-0" v-for="(castillo, index) in castillos" v-bind:key="index">
                                <CardsGeneral class="mx-auto" :subtitle="`${castillo.provincia}, ${castillo.municipio}`"
                                    :title="castillo.webNombres"
                                    :imageUrl="`${imagenesUrl}/monumentos/${castillo.oldId}/${castillo.banner}`"
                                    @goToUrl="goToCastillos(castillo.nombres?.find(item => item.idioma === $i18n.locale)?.texto)"
                                    type="monumento" />
                            </mdb-col>

                        </mdb-row>
                        <mdb-row class="mx-auto d-md-none">
                            <mdb-col col="12">
                                <button class="read-more-btn spainhn-gray-background mx-auto mb-1"
                                    @click="() => toggleFullCastles()">
                                    {{ $t('default.HOME_RESULTS_MORE_CASTLES') }}
                                </button>
                            </mdb-col>
                        </mdb-row>
                    </div>

                    <div v-else>
                        <div class="spainhn-header-info">
                            {{ $t("default.HOME_RESULTS_CASTLES_LABEL_NO_RESULT") }}
                            <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                icon="fa-location-dot" />
                        </div>
                    </div>

                </mdb-container>
                <!-- SUGERENCIAS -->
                <mdb-container class="px-0 px-md-2" fluid>

                    <div v-if="castillosProvincia?.length > 0" class="mt-5">
                        <div class="spainhn-header-info">
                            {{ $t("default.HOME_RESULTS_CASTLES_LABEL_PROVINCE", { provincia: provinciasName }) }}
                            <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                icon="fa-location-dot" />
                        </div>
                        <hr class="m-0 mt-1 mx-3">
                        <div class="d-md-block d-none mb-4" v-if="castillosProvincia.length > 0">
                            <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap" v-if="!clorian">

                                <Cards2 v-for="(castillo, index) in castillosProvincia" :key="index"
                                    :monument="castillo.webNombres"
                                    :provincia="castillo.provincia" :municipio="castillo.municipio"
                                    :imageUrl="`${imagenesUrl}/monumentos/${castillo.oldId}/${castillo.banner}`"
                                    :id="castillo._id"
                                    :nombreUrl="castillo.nombres?.find(item => item.idioma === $i18n.locale)?.texto"
                                    :text="castillo.webDescripciones" role="button" />

                            </div>
                        </div>
                        <mdb-row class="mx-auto d-md-none" v-bind:class="{ 'card-container': !showAllCastles }">

                            <mdb-col class="px-0" v-for="(castillo, index) in castillosProvincia" v-bind:key="index">
                                <CardsGeneral class="mx-auto" :subtitle="`${castillo.provincia}, ${castillo.municipio}`"
                                    :title="castillo.webNombres"
                                    :imageUrl="`${imagenesUrl}/monumentos/${castillo.oldId}/${castillo.banner}`"
                                    @goToUrl="goToCastillos(castillo.nombres?.find(item => item.idioma === $i18n.locale)?.texto)"
                                    type="monumento" />
                            </mdb-col>

                        </mdb-row>
                        <mdb-row class="mx-auto d-md-none">
                            <mdb-col col="12">
                                <button class="read-more-btn spainhn-gray-background mx-auto mb-1"
                                    @click="() => toggleFullCastles()">
                                    {{ $t('default.HOME_RESULTS_MORE_CASTLES') }}
                                </button>
                            </mdb-col>
                        </mdb-row>
                    </div>

                </mdb-container>
                <!-- PALACIOS -->
                <mdb-container class="px-0 px-md-2" fluid>

                    <div v-if="palacios?.length > 0" class="mt-5">
                        <hr class="m-0 mt-1 mx-3">
                        <mdb-badge class="spainhn-header-info"
                            style="background-color: var(--spainhn-rojo-spainhn) !important;">
                            {{ !sugerencias ? $t("default.HOME_RESULTS_NEAR_PALACES_LABEL") :
                                $t("default.HOME_RESULTS_PALACES_LABEL") }}
                            <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                icon="fa-location-dot" />
                        </mdb-badge>
                        <div class="d-md-block d-none mb-4" v-if="palacios.length > 0">
                            <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap" v-if="!clorian">
                                <Cards2 v-for="(palacio, index) in palacios" :key="index"
                                    :monument="palacio.webNombres"
                                    :provincia="palacio.provincia" :municipio="palacio.municipio"
                                    :imageUrl="`${imagenesUrl}/monumentos/${palacio.oldId}/${palacio.banner}`"
                                    :id="palacio._id" :text="palacio.descripciones?.[selectedIdiomaIndex]?.texto"
                                    :nombreUrl="palacio.nombres?.find(item => item.idioma === $i18n.locale)?.texto"
                                    role="button" />

                            </div>
                        </div>
                        <mdb-row class="mx-auto d-md-none">
                            <mdb-col class="px-0" v-for="(palacio, index) in palacios" v-bind:key="index">
                                <CardsGeneral class="mx-auto" :subtitle="`${palacio.provincia}, ${palacio.municipio}`"
                                    :title="palacio.webNombres"
                                    :imageUrl="`${imagenesUrl}/monumentos/${palacio.oldId}/${palacio.banner}`"
                                    @goToUrl="goToCastillos(palacio.nombres?.find(item => item.idioma === $i18n.locale)?.texto)"
                                    type="monumento" />
                            </mdb-col>
                            <mdb-col col="12">
                                <button class="read-more-btn spainhn-gray-background mx-auto mb-1">
                                    {{ $t('default.HOME_RESULTS_MORE_PALACES') }}
                                </button>
                            </mdb-col>
                        </mdb-row>

                    </div>

                </mdb-container>
                <!-- SUGERENCIAS -->
                <mdb-container class="px-0 px-md-2" fluid>
                    <div v-if="palaciosProvincia?.length > 0" class="mt-5">
                        <div class="spainhn-header-info">
                            {{ $t("default.HOME_RESULTS_PALACES_LABEL_PROVINCE", { provincia: provinciasName }) }}
                            <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                icon="fa-location-dot" />
                        </div>
                        <hr class="m-0 mt-1 mx-3">
                        <div class="d-md-block d-none mb-4" v-if="palaciosProvincia.length > 0">
                            <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap" v-if="!clorian">

                                <Cards2 v-for="(palacio, index) in palaciosProvincia" :key="index"
                                    :monument="palacio.webNombres"
                                    :provincia="palacio.provincia" :municipio="palacio.municipio"
                                    :imageUrl="`${imagenesUrl}/monumentos/${palacio.oldId}/${palacio.banner}`"
                                    :id="palacio._id" :text="palacio.descripciones?.[selectedIdiomaIndex]?.texto"
                                    :nombreUrl="palacio.nombres?.find(item => item.idioma === $i18n.locale)?.texto"
                                    role="button" />

                            </div>
                        </div>
                        <mdb-row class="mx-auto d-md-none">
                            <mdb-col class="px-0" v-for="(palacio, index) in palaciosProvincia" v-bind:key="index">
                                <CardsGeneral class="mx-auto" :subtitle="`${palacio.provincia}, ${palacio.municipio}`"
                                    :title="palacio.webNombres"
                                    :imageUrl="`${imagenesUrl}/monumentos/${palacio.oldId}/${palacio.banner}`"
                                    @goToUrl="goToCastillos(palacio.nombres?.find(item => item.idioma === $i18n.locale)?.texto)" />
                            </mdb-col>
                            <mdb-col col="12">
                                <button class="read-more-btn spainhn-gray-background mx-auto mb-1">
                                    {{ $t('default.HOME_RESULTS_MORE_PALACES') }}
                                </button>
                            </mdb-col>
                        </mdb-row>

                    </div>

                </mdb-container>
            </mdb-col>

            <mdb-col col="12" class="order-1">
                <h3 class="spainhn-subtitle text-uppercase">{{ $t("default.HOME_QUE_HACER_Y_VER") }}</h3>
                <!-- VISITAS -->
                <mdb-container class="px-0 px-md-2" fluid>

                    <div class="py-2" v-if="visitas.length">
                        <!-- <div class="spainhn-header-info">
                    {{ !sugerencias ? $t("default.HOME_RESULTS_SINGLE_VISITS_LABEL") :
                        $t("default.HOME_RESULTS_VISITS_LABEL") }}
                    <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                        icon="fa-location-dot" />
                </div> -->
                        <hr class="m-0 mt-1 mb-2 mx-3">
                        <mdb-badge class="spainhn-header-info text-start" color="danger"
                            style="background-color: var(--spainhn-rojo-spainhn) !important;">
                            {{ !sugerencias ? $t("default.HOME_RESULTS_SINGLE_VISITS_LABEL") :
                                $t("default.HOME_RESULTS_VISITS_LABEL") }}
                            <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                icon="fa-location-dot" />
                        </mdb-badge>
                        <!-- This is shown only in medium size screens -->
                        <div class="d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap"
                            v-if="!clorian">
                            <template v-if="mostrandoResultados">
                                <ResultsCards v-for="(visita, index) in visitas" v-bind:key="index"
                                    @goToEntrada="(evt) => goToEntrada({ entradaId: evt.entradaId, monumentoUrl: visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto })"
                                    :entradaId="visita._id" :monumentoNombreURL="visita.nombre"
                                    :imageUrl="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                    :title="visita.webNombres"
                                    :monument="visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto"
                                    :apiDeVentasUrl="visita.apiDeVentasActive ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}` : ''"
                                    :precioOrientativo="visita?.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(visita?.precioOrientativo)}) : '' "></ResultsCards>
                            </template>
                            <template v-else>
                                <ResultsCards v-for="(visita, index) in visitas" v-bind:key="index"
                                    @goToEntrada="(evt) => goToEntrada({ entradaId: evt.entradaId, monumentoUrl: visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto })"
                                    :entradaId="visita._id" :monumentoNombreURL="visita.nombre"
                                    :imageUrl="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                    :title="visita.webNombres"
                                    :monument="visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto"
                                    :apiDeVentasUrl="visita.apiDeVentasActive ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}` : ''"
                                    :precioOrientativo="visita?.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(visita?.precioOrientativo)}) : '' "></ResultsCards>
                            </template>
                        </div>

                        <mdb-row class="mx-auto d-md-none" v-bind:class="{ 'card-container': !showAllVisits }">
                            <mdb-col class="px-0" v-for="(visita, index) of visitas" v-bind:key="index">
                                <CardsGeneral class="mx-auto"
                                    :title="visita.webNombres"
                                    :subtitle="`${visita.municipio}, ${visita.provincia}`" :bold="true" :muted="true"
                                    :content="`${visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto || visita.comunidad}`"
                                    :image-url="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                    @goToBuy="goToEntrada({ entradaId: visita._id, monumentoUrl: visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto })"
                                    :buyBtn="true"
                                    @goToUrl="() => goToBuy({ entradaId: visita._id, oldId: visita.oldId, apiDeVentasActive: visita.apiDeVentasActive, apiDeVentasUrl: visita.apiDeVentasUrl })" />
                            </mdb-col>
                        </mdb-row>

                        <mdb-row :class="{ 'd-none': visitas.length === 0 }" class="mx-auto d-md-none">
                            <mdb-col col="12">
                                <button class="read-more-btn spainhn-gray-background mx-auto mb-0"
                                    @click="() => toggleFullVisits()">
                                    {{ !showAllVisits ? $t('default.HOME_RESULTS_MORE_VISITS2') :
                                        $t('default.HOME_RESULTS_LESS_VISITS2') }}
                                </button>
                            </mdb-col>
                        </mdb-row>

                    </div>

                </mdb-container>
                <!-- EVENTOS -->
                <mdb-container class="px-0 px-md-2" fluid>

                    <div class="py-2" v-if="eventos2?.length">
                        <hr class="m-0 mt-1 mb-2 mx-3">
                        <mdb-badge class="spainhn-header-info" color="danger"
                            style="background-color: var(--spainhn-rojo-spainhn) !important;">
                            {{ !sugerencias ? $t("default.HOME_RESULTS_SINGLE_EVENTS_LABEL") :
                                $t("default.HOME_RESULTS_EVENTS_LABEL") }}
                            <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                icon="fa-location-dot" />
                        </mdb-badge>
                        <!-- This is shown only in medium size screens -->
                        <div class="d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap"
                            v-if="!clorian">
                            <ResultsCards v-for="(visita, index) in eventos2" v-bind:key="index"
                                @goToEntrada="(evt) => goToEntrada({ entradaId: evt.entradaId, monumentoUrl: visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto })"
                                :entradaId="visita._id" :monumentoNombreURL="visita.nombre"
                                :imageUrl="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                :title="visita.webNombres"
                                :monument="visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto"
                                :precioOrientativo="visita?.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(visita?.precioOrientativo)}) : '' "
                                :apiDeVentasUrl="visita.apiDeVentasActive ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}` : ''"
                                tipo="Evento" :prox-date="visita.fechaInicioEvento" :date="visita.fechas">
                            </ResultsCards>
                        </div>

                        <mdb-row class="mx-auto d-md-none" v-bind:class="{ 'card-container': !showAllEvents }">
                            <mdb-col class="px-0" v-for="(visita, index) of eventos2" v-bind:key="index">
                                <CardsGeneral class="mx-auto"
                                    :title="visita.webNombres"
                                    :subtitle="`${visita.municipio}, ${visita.provincia}`" :bold="true" :muted="true"
                                    :content="`${visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto || visita.comunidad}`"
                                    :image-url="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                    @goToUrl="() => goToEntrada({ entradaId: visita._id, monumentoUrl: visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto })" />
                            </mdb-col>
                        </mdb-row>

                        <mdb-row :class="{ 'd-none': eventos2.length == 0 }" class="mx-auto d-md-none">
                            <mdb-col col="12">
                                <button class="read-more-btn spainhn-gray-background mx-auto mb-0"
                                    @click="() => toggleFullEvents()">
                                    {{ $t('default.HOME_RESULTS_MORE_VISITS') }}
                                </button>
                            </mdb-col>
                        </mdb-row>
                    </div>

                </mdb-container>
            </mdb-col>

            <mdb-col col="12" class="order-2">
                <h3 class="spainhn-subtitle text-uppercase"
                    :class="{ 'd-none': (paquetes.length === 0 && servicios.length === 0 && paquetesMonumentos.length === 0) }">
                    {{ $t("default.HOME_NUESTROS_PAQUETES") }}
                </h3>
                <!-- PAQUETES -->
                <mdb-container fluid>
                    <div class="py-2"
                        v-if="paquetes.length > 0 || servicios.length > 0 || paquetesMonumentos.length > 0">
                        <hr class="m-0 mt-1 mb-2 mx-3">
                        <mdb-badge class="spainhn-header-info"
                            style="background-color: var(--spainhn-rojo-spainhn) !important;">
                            {{ $t("default.HOME_RESULTS.SERVICIOS_TITLE") }}
                            <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                icon="fa-location-dot" />
                        </mdb-badge>

                        <div>
                            <mdb-row class="gx-3 gy-3 py-2">
                                <mdb-col v-for="(paqueteM, index) in paquetesMonumentos" :key="index">
                                    <ResultsCards class="mx-auto" :title="paqueteM.name" :entradaId="paqueteM._id"
                                        :showDefault="false" :monumentoNombreURL="paqueteM.name"
                                        :monument="`Lugar: ${paqueteM.monumentoId.nombre}`"
                                        :precioOrientativo="paqueteM?.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(visita?.precioOrientativo)}) : $t('default.CARDS.START_FROM', { msg: getPrice(paqueteM?.content?.reduce((acc, cur) => acc + Number(cur.precioOrientativo), 0))})"
                                        :image-url="`${imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`"
                                        :showBuyButton="true" tipo="paquetes_monumentos"
                                        @goToEntrada="() => goToMicrositioPaqueteMonumento({ name: paqueteM.name, id: paqueteM._id })"
                                        @buy="() => goToBuy(paqueteM, true)">
                                    </ResultsCards>
                                </mdb-col>
                                <mdb-col v-for="product of paquetes.concat(servicios)" :key="product._id">
                                    <ResultsCards class="mx-auto"
                                        :title="product.nombres?.find(item => item.idioma === $i18n.locale)?.texto ?? product.nombreServicio"
                                        :showDefault="false" :entradaId="product._id"
                                        :monumentoNombreURL="product.nombre" :text="`Lugar: ${product.place}`"
                                        :monument="`Lugar: ${product.place}`" tipo="paquetes_prestadores"
                                        :precioOrientativo="product?.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(product?.precioOrientativo)}) : ''"
                                        :image-url="`${imagenesUrl}/${product.imageLabel}/${product.oldId ? product.oldId + '/' + encodeURI(product.banner) : product.prestadorId + '/' + product.banner}`"
                                        :showBuyButton="true" @goTo="goToEntrada(product)"
                                        @goToEntrada="() => goToEntrada(product)" @buy="() => goToBuy(product)">
                                    </ResultsCards>
                                </mdb-col>
                            </mdb-row>
                        </div>
                    </div>
                </mdb-container>
            </mdb-col>

            <mdb-col col="12" class="order-3">
                <h3 class="spainhn-subtitle text-uppercase">{{ $t("default.HOME_NUESTRAS_RUTAS") }}</h3>
                <!-- RUTAS -->
                <mdb-container class="px-0 px-md-2" fluid>
                    <div class="py-2" v-if="rutas">
                        <hr class="m-0 mt-1 mb-2 mx-3">
                        <mdb-badge class="spainhn-header-info"
                            style="background-color: var(--spainhn-rojo-spainhn) !important;">
                            {{ !sugerencias ? $t("default.HOME_RESULTS_NEAR_ROUTES_LABEL") :
                                $t("default.HOME_RESULTS_ROUTES_LABEL")
                            }}
                            <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                icon="fa-location-dot" />
                        </mdb-badge>
                        <!-- This is shown only in medium size screens -->
                        <div class="d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap"
                            v-if="!clorian">
                            <Cards3 v-for="( ruta, index ) in rutas" :key="index" :showSubtitle="false"
                                :title="ruta.nombres?.find(item => item.idioma === $i18n.locale)?.texto"
                                :image-url="`${imagenesUrl}/rutas/logo/${ruta?.rutaId}`"
                                @goTo="() => goToRoute(ruta._id)" />

                        </div>

                        <mdb-row class="mx-auto d-md-none" v-bind:class="{ 'card-container': !showAllRoutes }">
                            <mdb-col class="px-0" v-for="(ruta, index) of rutas" v-bind:key="index">
                                <CardsGeneral class="mx-auto"
                                    :title="ruta.nombres?.find(item => item.idioma === $i18n.locale)?.texto" :bold="true"
                                    :muted="true"
                                    :content="`${ruta.duracion ? ruta.duracion?.find(item => item.idioma === $i18n.locale)?.texto : '1 día'}`"
                                    :image-url="`${imagenesUrl}/rutas/logo/${ruta.rutaId}`"
                                    @goToUrl="() => goToRoute(ruta._id)" />
                            </mdb-col>

                        </mdb-row>

                        <mdb-row class="mx-auto d-md-none" :class="{ 'd-none': rutas.length == 0 }">
                            <mdb-col col="12">
                                <button class="read-more-btn spainhn-gray-background mx-auto mb-1"
                                    @click="() => toggleFullRoutes()">
                                    {{ $t('default.HOME_RESULTS_MORE_ROUTES') }}
                                </button>
                            </mdb-col>
                        </mdb-row>
                    </div>
                </mdb-container>
            </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import Cards2 from "../Cards2";
import ResultsCards from "../ResultsCards";
import CardsGeneral from "../CardsGeneral";
import Cards3 from "../Cards3.vue";

export default {
    components: {
        Cards2,
        ResultsCards,
        CardsGeneral,
        Cards3
    },
    props:{
        sugerencias: { type: Boolean, default: false, required: false },
        castillos: { type: Array, required: false, default: () => []},
        castillosProvincia: { type: Array, required: false, default: () => []},
        palacios: { type: Array, required: false, default: () => []},
        palaciosProvincia: { type: Array, required: false, default: () => []},
        monumentos: { type: Array, required: false, default: () => []},
        visitas: { type: Array, required: false, default: () => []},
        paquetes: { type: Array, required: false, default: () => []},
        paquetesMonumentos: { type: Array, required: false, default: () => []},
        eventos2: { type: Array, required: false, default: () => []},
        rutas: { type: Array, required: false, default: () => []},
        servicios: { type: Array, required: false, default: () => []}
    },
    data(){
        return{
            mostrandoResultados: false,
            imagenesUrl: null,
            showAllRoutes: false,
            showAllCastles: false,
            showAllPalaces: false,
            showAllVisits: false,
            showAllServices: false,
            showAllEvents: false,
            clorian: false
        }
    },
    methods: {
        goToEventos() {
            let $vm = this;
            $vm.$router.push('/eventos-singulares');
        },
        goToExperiences() {
            let $vm = this;
            $vm.$router.push('/experiencias-exclusivas');
        },
        encodeUrl(name = '') {
            let rawName = name.replaceAll(',', '');
            rawName = rawName.replaceAll('.', '');

            let sanitizedName = rawName.normalize("NFD").replace(/[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g, '')
            let sanitizedNameLowercase = sanitizedName.toLowerCase().replace(/[\u0020]/g, '-');

            return sanitizedNameLowercase
        },
        goToEntrada(event) {
            if (this.monumentoUrl === 'castillo-de-san-pedro-de-jaca-(ciudadela)') {
                window.open(process.env.VUE_APP_JACA_URL);
            }
            else {
                if (event.imageLabel === 'paquetes_prestadores') {
                    this.$router.push({ name: 'MicrositioPaquete', params: { 'paqueteNombre': this.encodeUrl(event.nombres[0].texto), paqueteId: event._id } })
                } else {
                    this.$router.push('/castillos/tarifas/' + this.encodeUrl(event.monumentoUrl) + '/' + event.entradaId)
                }
            }
        },
        goToMicrositioPaqueteMonumento({ name, id }) {
            console.log('🚀 ~ goToMicrositioPaqueteMonumento ~ name:', name)
            this.$router.push({ name: 'MicrositioPaqueteMonumento', params: { paqueteNombre: this.encodeUrl(name) || 'name', paqueteId: id } })
        },
        goToBuy(data, paqueteMonumento = false) {
            if (data.apiDeVentasActive && data.apiDeVentasUrl !== 'clorian') {
                this.$toast.success(this.$t("default.NOTIFICATIONS.REDIRECT"));
                setTimeout(() => {
                    window.open(`${data.apiDeVentasUrl}?idProducto=${data.oldId}`, '_blank');
                }, 2000)
            } else {

                if (data.imageLabel === 'paquetes_prestadores') {
                    this.$router.push({ name: "ReservarPackExperiencias", query: { tipo: 'paquete', id: data._id } });
                }
                else if (data.imageLabel === "servicios_prestadores") {
                    this.$router.push({ name: "ReservarPackExperiencias", query: { tipo: 'servicio', id: data._id } });
                }
                else if (paqueteMonumento) {
                    this.$router.push({ name: "ReservarPackMonumentos", query: { tipo: 'monumentos', id: data._id } });
                }
                else {
                    this.$router.push(`/castillos/reservar/idvisita:${data.entradaId}`)
                }
            }
        },
        goToEvento(event) {
            this.$router.push('/castillos/eventos/' + this.monumentoUrl + '/' + event.entradaId)
        },
        goToCastillos(name) {
            let $vm = this;
            $vm.$router.push('/castillos/' + this.encodeUrl(name))
        },
        goToRoute(event) {
            this.$router.push('/ruta/' + event)
        },
        toggleFullCastles() {
            if (this.showAllCastles) {
                this.$router.push('/castillos')
            }
            else {
                if (this.castillos.length <= 2) {
                    this.$router.push('/castillos')
                }
                else {
                    this.showAllCastles = true;
                }
            }
        },
        toggleFullPalaces() {
            if (this.showAllPalaces) {
                this.$router.push('/castillos')
            }
            else {
                if (this.palacios.length <= 2) {
                    this.$router.push('/castillos')
                }
                else {
                    this.showAllPalaces = true;
                }
            }
        },
        toggleFullEvents() {
            if (this.showAllEvents) {
                this.$router.push('/castillos/servicios/eventos-singulares')
            }
            else {
                if (this.visitas.length <= 2) {
                    this.$router.push('/castillos/servicios/eventos-singulares')
                }
                else {
                    this.showAllEvents = true;
                }
            }
        },
        toggleFullVisits() {
            this.showAllVisits = !this.showAllVisits;
        },
        toggleFullRoutes() {
            if (this.showAllRoutes) {
                this.$router.push('/rutas-recomendadas')
            }
            else {
                if (this.rutas.length <= 2) {
                    this.$router.push('/rutas-recomendadas');
                }
                else {
                    this.showAllRoutes = true;
                }
            }
        },
        getPrice( precioOrientativo ){
            if( precioOrientativo ){
                if( isNaN(Number(precioOrientativo)) ){
                    return '';
                }else{
                    return `${parseFloat(Number(precioOrientativo))?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}`
                }
            }else{
                return '';
            }
        },
    },
    computed: {
        selectedIdiomaIndex() {
            let $vm = this;
            let idiomaIndex = 0;
            if ($vm.$i18n.locale == 'es') {
                idiomaIndex = 0;
            }
            else if ($vm.$i18n.locale == 'en') {
                idiomaIndex = 1;
            }
            else if ($vm.$i18n.locale == 'fr') {
                idiomaIndex = 2;
            }
            else if ($vm.$i18n.locale == 'de') {
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma() {
            let $vm = this;

            return $vm.$i18n.locale ?? 'es';
        },
    },
    async beforeMount() {
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
}
</script>

<style scoped>
.mobile-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 15px;
}

.disable-selector {
    color: rgba(225, 225, 225);
}

.header-text {
    font-size: 14px;
    color: #FFF;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    max-height: 80%;
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    -webkit-line-clamp: 4;
}

.header-title {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.read-more-btn {
    width: 120px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--RUTA, #BF5B5B);
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    padding: 0;
}

.thumbnails-container {
    transition: all 0.5s;
}

.thumbnail-slide {
    /* position:absolute; */
    transform: translate(0px);
    transition: all 0.5s;
}

@media (min-width: 768px) {
    .text-columns {
        column-count: 2;
        /* height: 50vh; */
    }

    .carousel-spainhn {
        height: 528px;
        width: auto;
        /* max-width: 100vw; */
    }
}

@media (max-width: 768px) {
    .text-columns {
        column-count: 2;
        /* height: 50vh; */
    }

    .carousel-homeslide {
        height: 300px !important;
    }

    .carousel-spainhn {
        height: 100%;
        width: 100% !important;
        max-width: 100vw;
    }

}

@media (max-width: 424px) {
    .text-columns {
        column-count: 1;
        /* height: 50vh; */
    }
}

#monumentosShow___BV_indicators_>ol {
    margin: 0px !important;
}

.html-container {
    position: relative;
    overflow: hidden;
    max-height: 320px;
}

.html-container p span {
    color: black !important;
}

.html-container p {
    color: black !important;
}

.read-more-btn {
    width: 120px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #D9D9D9;
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    padding: 0;
}

.welcome-message {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow-wrap: break-word;
    margin: 0;
}

@media only screen and (min-width: 768px) {
    .welcome-message {
        font-size: 25px;
        /* Adjust the font size for medium screens */
        padding: 10px 0;
    }
}

.card-container {
    position: relative;
    overflow: hidden;
    max-height: 270px;
}

.rounded-end-pill::after {
    content: none;
}

.color-corp {
    color: var(--spainhn-rojo-corporativo);
}

.slider-img-2 {
    width: 100%;
    height: 100%;
    object-fit: cover;

}
</style>