var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid pt-4 px-1 px-md-3" },
    [
      _c(
        "mdb-row",
        { staticClass: "gy-3" },
        [
          _c(
            "mdb-col",
            { staticClass: "order-3", attrs: { col: "12" } },
            [
              _c("h3", { staticClass: "spainhn-subtitle text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("default.HOME_NUESTROS_MONUMENTOS"))),
              ]),
              _c(
                "mdb-container",
                { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                [
                  _vm.castillos?.length > 0
                    ? _c(
                        "div",
                        { staticClass: "mt-5" },
                        [
                          _c("hr", { staticClass: "m-0 mt-1 mx-3" }),
                          _c(
                            "mdb-badge",
                            {
                              staticClass: "spainhn-header-info text-uppercase",
                              staticStyle: {
                                "background-color":
                                  "var(--spainhn-rojo-spainhn) !important",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.sugerencias
                                      ? _vm.$t(
                                          "default.HOME_RESULTS_NEAR_CASTLES_LABEL"
                                        )
                                      : _vm.$t(
                                          "default.HOME_RESULTS_CASTLES_LABEL"
                                        )
                                  ) +
                                  " "
                              ),
                              _c("font-awesome-icon", {
                                class: { "d-none": _vm.sugerencias },
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: { icon: "fa fa-location-dot" },
                              }),
                            ],
                            1
                          ),
                          _vm.castillos.length > 0
                            ? _c(
                                "div",
                                { staticClass: "d-md-block d-none mb-4" },
                                [
                                  !_vm.clorian
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                                        },
                                        _vm._l(
                                          _vm.castillos,
                                          function (castillo, index) {
                                            return _c("Cards2", {
                                              key: index,
                                              attrs: {
                                                monument: castillo.webNombres,
                                                provincia: castillo.provincia,
                                                municipio: castillo.municipio,
                                                imageUrl: `${_vm.imagenesUrl}/monumentos/${castillo.oldId}/${castillo.banner}`,
                                                id: castillo._id,
                                                nombreUrl:
                                                  castillo.nombres?.find(
                                                    (item) =>
                                                      item.idioma ===
                                                      _vm.$i18n.locale
                                                  )?.texto,
                                                text: castillo.descripciones?.[
                                                  _vm.selectedIdiomaIndex
                                                ]?.texto,
                                                role: "button",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "mdb-row",
                            {
                              staticClass: "mx-auto d-md-none",
                              class: { "card-container": !_vm.showAllCastles },
                            },
                            _vm._l(_vm.castillos, function (castillo, index) {
                              return _c(
                                "mdb-col",
                                { key: index, staticClass: "px-0" },
                                [
                                  _c("CardsGeneral", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      subtitle: `${castillo.provincia}, ${castillo.municipio}`,
                                      title: castillo.webNombres,
                                      imageUrl: `${_vm.imagenesUrl}/monumentos/${castillo.oldId}/${castillo.banner}`,
                                      type: "monumento",
                                    },
                                    on: {
                                      goToUrl: function ($event) {
                                        _vm.goToCastillos(
                                          castillo.nombres?.find(
                                            (item) =>
                                              item.idioma === _vm.$i18n.locale
                                          )?.texto
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "mdb-row",
                            { staticClass: "mx-auto d-md-none" },
                            [
                              _c("mdb-col", { attrs: { col: "12" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "read-more-btn spainhn-gray-background mx-auto mb-1",
                                    on: {
                                      click: () => _vm.toggleFullCastles(),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "default.HOME_RESULTS_MORE_CASTLES"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "spainhn-header-info" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "default.HOME_RESULTS_CASTLES_LABEL_NO_RESULT"
                                  )
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              class: { "d-none": _vm.sugerencias },
                              staticStyle: { width: "20px", height: "20px" },
                              attrs: { icon: "fa-location-dot" },
                            }),
                          ],
                          1
                        ),
                      ]),
                ]
              ),
              _c(
                "mdb-container",
                { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                [
                  _vm.castillosProvincia?.length > 0
                    ? _c(
                        "div",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "div",
                            { staticClass: "spainhn-header-info" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "default.HOME_RESULTS_CASTLES_LABEL_PROVINCE",
                                      { provincia: _vm.provinciasName }
                                    )
                                  ) +
                                  " "
                              ),
                              _c("font-awesome-icon", {
                                class: { "d-none": _vm.sugerencias },
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: { icon: "fa-location-dot" },
                              }),
                            ],
                            1
                          ),
                          _c("hr", { staticClass: "m-0 mt-1 mx-3" }),
                          _vm.castillosProvincia.length > 0
                            ? _c(
                                "div",
                                { staticClass: "d-md-block d-none mb-4" },
                                [
                                  !_vm.clorian
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                                        },
                                        _vm._l(
                                          _vm.castillosProvincia,
                                          function (castillo, index) {
                                            return _c("Cards2", {
                                              key: index,
                                              attrs: {
                                                monument: castillo.webNombres,
                                                provincia: castillo.provincia,
                                                municipio: castillo.municipio,
                                                imageUrl: `${_vm.imagenesUrl}/monumentos/${castillo.oldId}/${castillo.banner}`,
                                                id: castillo._id,
                                                nombreUrl:
                                                  castillo.nombres?.find(
                                                    (item) =>
                                                      item.idioma ===
                                                      _vm.$i18n.locale
                                                  )?.texto,
                                                text: castillo.webDescripciones,
                                                role: "button",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "mdb-row",
                            {
                              staticClass: "mx-auto d-md-none",
                              class: { "card-container": !_vm.showAllCastles },
                            },
                            _vm._l(
                              _vm.castillosProvincia,
                              function (castillo, index) {
                                return _c(
                                  "mdb-col",
                                  { key: index, staticClass: "px-0" },
                                  [
                                    _c("CardsGeneral", {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        subtitle: `${castillo.provincia}, ${castillo.municipio}`,
                                        title: castillo.webNombres,
                                        imageUrl: `${_vm.imagenesUrl}/monumentos/${castillo.oldId}/${castillo.banner}`,
                                        type: "monumento",
                                      },
                                      on: {
                                        goToUrl: function ($event) {
                                          _vm.goToCastillos(
                                            castillo.nombres?.find(
                                              (item) =>
                                                item.idioma === _vm.$i18n.locale
                                            )?.texto
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                          _c(
                            "mdb-row",
                            { staticClass: "mx-auto d-md-none" },
                            [
                              _c("mdb-col", { attrs: { col: "12" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "read-more-btn spainhn-gray-background mx-auto mb-1",
                                    on: {
                                      click: () => _vm.toggleFullCastles(),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "default.HOME_RESULTS_MORE_CASTLES"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "mdb-container",
                { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                [
                  _vm.palacios?.length > 0
                    ? _c(
                        "div",
                        { staticClass: "mt-5" },
                        [
                          _c("hr", { staticClass: "m-0 mt-1 mx-3" }),
                          _c(
                            "mdb-badge",
                            {
                              staticClass: "spainhn-header-info",
                              staticStyle: {
                                "background-color":
                                  "var(--spainhn-rojo-spainhn) !important",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.sugerencias
                                      ? _vm.$t(
                                          "default.HOME_RESULTS_NEAR_PALACES_LABEL"
                                        )
                                      : _vm.$t(
                                          "default.HOME_RESULTS_PALACES_LABEL"
                                        )
                                  ) +
                                  " "
                              ),
                              _c("font-awesome-icon", {
                                class: { "d-none": _vm.sugerencias },
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: { icon: "fa-location-dot" },
                              }),
                            ],
                            1
                          ),
                          _vm.palacios.length > 0
                            ? _c(
                                "div",
                                { staticClass: "d-md-block d-none mb-4" },
                                [
                                  !_vm.clorian
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                                        },
                                        _vm._l(
                                          _vm.palacios,
                                          function (palacio, index) {
                                            return _c("Cards2", {
                                              key: index,
                                              attrs: {
                                                monument: palacio.webNombres,
                                                provincia: palacio.provincia,
                                                municipio: palacio.municipio,
                                                imageUrl: `${_vm.imagenesUrl}/monumentos/${palacio.oldId}/${palacio.banner}`,
                                                id: palacio._id,
                                                text: palacio.descripciones?.[
                                                  _vm.selectedIdiomaIndex
                                                ]?.texto,
                                                nombreUrl:
                                                  palacio.nombres?.find(
                                                    (item) =>
                                                      item.idioma ===
                                                      _vm.$i18n.locale
                                                  )?.texto,
                                                role: "button",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "mdb-row",
                            { staticClass: "mx-auto d-md-none" },
                            [
                              _vm._l(_vm.palacios, function (palacio, index) {
                                return _c(
                                  "mdb-col",
                                  { key: index, staticClass: "px-0" },
                                  [
                                    _c("CardsGeneral", {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        subtitle: `${palacio.provincia}, ${palacio.municipio}`,
                                        title: palacio.webNombres,
                                        imageUrl: `${_vm.imagenesUrl}/monumentos/${palacio.oldId}/${palacio.banner}`,
                                        type: "monumento",
                                      },
                                      on: {
                                        goToUrl: function ($event) {
                                          _vm.goToCastillos(
                                            palacio.nombres?.find(
                                              (item) =>
                                                item.idioma === _vm.$i18n.locale
                                            )?.texto
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              _c("mdb-col", { attrs: { col: "12" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "read-more-btn spainhn-gray-background mx-auto mb-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "default.HOME_RESULTS_MORE_PALACES"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "mdb-container",
                { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                [
                  _vm.palaciosProvincia?.length > 0
                    ? _c(
                        "div",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "div",
                            { staticClass: "spainhn-header-info" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "default.HOME_RESULTS_PALACES_LABEL_PROVINCE",
                                      { provincia: _vm.provinciasName }
                                    )
                                  ) +
                                  " "
                              ),
                              _c("font-awesome-icon", {
                                class: { "d-none": _vm.sugerencias },
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: { icon: "fa-location-dot" },
                              }),
                            ],
                            1
                          ),
                          _c("hr", { staticClass: "m-0 mt-1 mx-3" }),
                          _vm.palaciosProvincia.length > 0
                            ? _c(
                                "div",
                                { staticClass: "d-md-block d-none mb-4" },
                                [
                                  !_vm.clorian
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                                        },
                                        _vm._l(
                                          _vm.palaciosProvincia,
                                          function (palacio, index) {
                                            return _c("Cards2", {
                                              key: index,
                                              attrs: {
                                                monument: palacio.webNombres,
                                                provincia: palacio.provincia,
                                                municipio: palacio.municipio,
                                                imageUrl: `${_vm.imagenesUrl}/monumentos/${palacio.oldId}/${palacio.banner}`,
                                                id: palacio._id,
                                                text: palacio.descripciones?.[
                                                  _vm.selectedIdiomaIndex
                                                ]?.texto,
                                                nombreUrl:
                                                  palacio.nombres?.find(
                                                    (item) =>
                                                      item.idioma ===
                                                      _vm.$i18n.locale
                                                  )?.texto,
                                                role: "button",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "mdb-row",
                            { staticClass: "mx-auto d-md-none" },
                            [
                              _vm._l(
                                _vm.palaciosProvincia,
                                function (palacio, index) {
                                  return _c(
                                    "mdb-col",
                                    { key: index, staticClass: "px-0" },
                                    [
                                      _c("CardsGeneral", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          subtitle: `${palacio.provincia}, ${palacio.municipio}`,
                                          title: palacio.webNombres,
                                          imageUrl: `${_vm.imagenesUrl}/monumentos/${palacio.oldId}/${palacio.banner}`,
                                        },
                                        on: {
                                          goToUrl: function ($event) {
                                            _vm.goToCastillos(
                                              palacio.nombres?.find(
                                                (item) =>
                                                  item.idioma ===
                                                  _vm.$i18n.locale
                                              )?.texto
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c("mdb-col", { attrs: { col: "12" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "read-more-btn spainhn-gray-background mx-auto mb-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "default.HOME_RESULTS_MORE_PALACES"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "mdb-col",
            { staticClass: "order-1", attrs: { col: "12" } },
            [
              _c("h3", { staticClass: "spainhn-subtitle text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("default.HOME_QUE_HACER_Y_VER"))),
              ]),
              _c(
                "mdb-container",
                { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                [
                  _vm.visitas.length
                    ? _c(
                        "div",
                        { staticClass: "py-2" },
                        [
                          _c("hr", { staticClass: "m-0 mt-1 mb-2 mx-3" }),
                          _c(
                            "mdb-badge",
                            {
                              staticClass: "spainhn-header-info text-start",
                              staticStyle: {
                                "background-color":
                                  "var(--spainhn-rojo-spainhn) !important",
                              },
                              attrs: { color: "danger" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.sugerencias
                                      ? _vm.$t(
                                          "default.HOME_RESULTS_SINGLE_VISITS_LABEL"
                                        )
                                      : _vm.$t(
                                          "default.HOME_RESULTS_VISITS_LABEL"
                                        )
                                  ) +
                                  " "
                              ),
                              _c("font-awesome-icon", {
                                class: { "d-none": _vm.sugerencias },
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: { icon: "fa-location-dot" },
                              }),
                            ],
                            1
                          ),
                          !_vm.clorian
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap",
                                },
                                [
                                  _vm.mostrandoResultados
                                    ? _vm._l(
                                        _vm.visitas,
                                        function (visita, index) {
                                          return _c("ResultsCards", {
                                            key: index,
                                            attrs: {
                                              entradaId: visita._id,
                                              monumentoNombreURL: visita.nombre,
                                              imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`,
                                              title: visita.webNombres,
                                              monument:
                                                visita.castilloNombres?.find(
                                                  (item) =>
                                                    item.idioma ===
                                                    _vm.$i18n.locale
                                                )?.texto,
                                              apiDeVentasUrl:
                                                visita.apiDeVentasActive
                                                  ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}`
                                                  : "",
                                              precioOrientativo:
                                                visita?.precioOrientativo
                                                  ? _vm.$t(
                                                      "default.CARDS.START_FROM",
                                                      {
                                                        msg: _vm.getPrice(
                                                          visita?.precioOrientativo
                                                        ),
                                                      }
                                                    )
                                                  : "",
                                            },
                                            on: {
                                              goToEntrada: (evt) =>
                                                _vm.goToEntrada({
                                                  entradaId: evt.entradaId,
                                                  monumentoUrl:
                                                    visita.castilloNombres?.find(
                                                      (item) =>
                                                        item.idioma ===
                                                        _vm.$i18n.locale
                                                    )?.texto,
                                                }),
                                            },
                                          })
                                        }
                                      )
                                    : _vm._l(
                                        _vm.visitas,
                                        function (visita, index) {
                                          return _c("ResultsCards", {
                                            key: index,
                                            attrs: {
                                              entradaId: visita._id,
                                              monumentoNombreURL: visita.nombre,
                                              imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`,
                                              title: visita.webNombres,
                                              monument:
                                                visita.castilloNombres?.find(
                                                  (item) =>
                                                    item.idioma ===
                                                    _vm.$i18n.locale
                                                )?.texto,
                                              apiDeVentasUrl:
                                                visita.apiDeVentasActive
                                                  ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}`
                                                  : "",
                                              precioOrientativo:
                                                visita?.precioOrientativo
                                                  ? _vm.$t(
                                                      "default.CARDS.START_FROM",
                                                      {
                                                        msg: _vm.getPrice(
                                                          visita?.precioOrientativo
                                                        ),
                                                      }
                                                    )
                                                  : "",
                                            },
                                            on: {
                                              goToEntrada: (evt) =>
                                                _vm.goToEntrada({
                                                  entradaId: evt.entradaId,
                                                  monumentoUrl:
                                                    visita.castilloNombres?.find(
                                                      (item) =>
                                                        item.idioma ===
                                                        _vm.$i18n.locale
                                                    )?.texto,
                                                }),
                                            },
                                          })
                                        }
                                      ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "mdb-row",
                            {
                              staticClass: "mx-auto d-md-none",
                              class: { "card-container": !_vm.showAllVisits },
                            },
                            _vm._l(_vm.visitas, function (visita, index) {
                              return _c(
                                "mdb-col",
                                { key: index, staticClass: "px-0" },
                                [
                                  _c("CardsGeneral", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      title: visita.webNombres,
                                      subtitle: `${visita.municipio}, ${visita.provincia}`,
                                      bold: true,
                                      muted: true,
                                      content: `${
                                        visita.castilloNombres?.find(
                                          (item) =>
                                            item.idioma === _vm.$i18n.locale
                                        )?.texto || visita.comunidad
                                      }`,
                                      "image-url": `${_vm.imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`,
                                      buyBtn: true,
                                    },
                                    on: {
                                      goToBuy: function ($event) {
                                        _vm.goToEntrada({
                                          entradaId: visita._id,
                                          monumentoUrl:
                                            visita.castilloNombres?.find(
                                              (item) =>
                                                item.idioma === _vm.$i18n.locale
                                            )?.texto,
                                        })
                                      },
                                      goToUrl: () =>
                                        _vm.goToBuy({
                                          entradaId: visita._id,
                                          oldId: visita.oldId,
                                          apiDeVentasActive:
                                            visita.apiDeVentasActive,
                                          apiDeVentasUrl: visita.apiDeVentasUrl,
                                        }),
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "mdb-row",
                            {
                              staticClass: "mx-auto d-md-none",
                              class: { "d-none": _vm.visitas.length === 0 },
                            },
                            [
                              _c("mdb-col", { attrs: { col: "12" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "read-more-btn spainhn-gray-background mx-auto mb-0",
                                    on: { click: () => _vm.toggleFullVisits() },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.showAllVisits
                                            ? _vm.$t(
                                                "default.HOME_RESULTS_MORE_VISITS2"
                                              )
                                            : _vm.$t(
                                                "default.HOME_RESULTS_LESS_VISITS2"
                                              )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "mdb-container",
                { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                [
                  _vm.eventos2?.length
                    ? _c(
                        "div",
                        { staticClass: "py-2" },
                        [
                          _c("hr", { staticClass: "m-0 mt-1 mb-2 mx-3" }),
                          _c(
                            "mdb-badge",
                            {
                              staticClass: "spainhn-header-info",
                              staticStyle: {
                                "background-color":
                                  "var(--spainhn-rojo-spainhn) !important",
                              },
                              attrs: { color: "danger" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.sugerencias
                                      ? _vm.$t(
                                          "default.HOME_RESULTS_SINGLE_EVENTS_LABEL"
                                        )
                                      : _vm.$t(
                                          "default.HOME_RESULTS_EVENTS_LABEL"
                                        )
                                  ) +
                                  " "
                              ),
                              _c("font-awesome-icon", {
                                class: { "d-none": _vm.sugerencias },
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: { icon: "fa-location-dot" },
                              }),
                            ],
                            1
                          ),
                          !_vm.clorian
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap",
                                },
                                _vm._l(_vm.eventos2, function (visita, index) {
                                  return _c("ResultsCards", {
                                    key: index,
                                    attrs: {
                                      entradaId: visita._id,
                                      monumentoNombreURL: visita.nombre,
                                      imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`,
                                      title: visita.webNombres,
                                      monument: visita.castilloNombres?.find(
                                        (item) =>
                                          item.idioma === _vm.$i18n.locale
                                      )?.texto,
                                      precioOrientativo:
                                        visita?.precioOrientativo
                                          ? _vm.$t("default.CARDS.START_FROM", {
                                              msg: _vm.getPrice(
                                                visita?.precioOrientativo
                                              ),
                                            })
                                          : "",
                                      apiDeVentasUrl: visita.apiDeVentasActive
                                        ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}`
                                        : "",
                                      tipo: "Evento",
                                      "prox-date": visita.fechaInicioEvento,
                                      date: visita.fechas,
                                    },
                                    on: {
                                      goToEntrada: (evt) =>
                                        _vm.goToEntrada({
                                          entradaId: evt.entradaId,
                                          monumentoUrl:
                                            visita.castilloNombres?.find(
                                              (item) =>
                                                item.idioma === _vm.$i18n.locale
                                            )?.texto,
                                        }),
                                    },
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "mdb-row",
                            {
                              staticClass: "mx-auto d-md-none",
                              class: { "card-container": !_vm.showAllEvents },
                            },
                            _vm._l(_vm.eventos2, function (visita, index) {
                              return _c(
                                "mdb-col",
                                { key: index, staticClass: "px-0" },
                                [
                                  _c("CardsGeneral", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      title: visita.webNombres,
                                      subtitle: `${visita.municipio}, ${visita.provincia}`,
                                      bold: true,
                                      muted: true,
                                      content: `${
                                        visita.castilloNombres?.find(
                                          (item) =>
                                            item.idioma === _vm.$i18n.locale
                                        )?.texto || visita.comunidad
                                      }`,
                                      "image-url": `${_vm.imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`,
                                    },
                                    on: {
                                      goToUrl: () =>
                                        _vm.goToEntrada({
                                          entradaId: visita._id,
                                          monumentoUrl:
                                            visita.castilloNombres?.find(
                                              (item) =>
                                                item.idioma === _vm.$i18n.locale
                                            )?.texto,
                                        }),
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "mdb-row",
                            {
                              staticClass: "mx-auto d-md-none",
                              class: { "d-none": _vm.eventos2.length == 0 },
                            },
                            [
                              _c("mdb-col", { attrs: { col: "12" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "read-more-btn spainhn-gray-background mx-auto mb-0",
                                    on: { click: () => _vm.toggleFullEvents() },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "default.HOME_RESULTS_MORE_VISITS"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "mdb-col",
            { staticClass: "order-2", attrs: { col: "12" } },
            [
              _c(
                "h3",
                {
                  staticClass: "spainhn-subtitle text-uppercase",
                  class: {
                    "d-none":
                      _vm.paquetes.length === 0 &&
                      _vm.servicios.length === 0 &&
                      _vm.paquetesMonumentos.length === 0,
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("default.HOME_NUESTROS_PAQUETES")) + " "
                  ),
                ]
              ),
              _c("mdb-container", { attrs: { fluid: "" } }, [
                _vm.paquetes.length > 0 ||
                _vm.servicios.length > 0 ||
                _vm.paquetesMonumentos.length > 0
                  ? _c(
                      "div",
                      { staticClass: "py-2" },
                      [
                        _c("hr", { staticClass: "m-0 mt-1 mb-2 mx-3" }),
                        _c(
                          "mdb-badge",
                          {
                            staticClass: "spainhn-header-info",
                            staticStyle: {
                              "background-color":
                                "var(--spainhn-rojo-spainhn) !important",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("default.HOME_RESULTS.SERVICIOS_TITLE")
                                ) +
                                " "
                            ),
                            _c("font-awesome-icon", {
                              class: { "d-none": _vm.sugerencias },
                              staticStyle: { width: "20px", height: "20px" },
                              attrs: { icon: "fa-location-dot" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "mdb-row",
                              { staticClass: "gx-3 gy-3 py-2" },
                              [
                                _vm._l(
                                  _vm.paquetesMonumentos,
                                  function (paqueteM, index) {
                                    return _c(
                                      "mdb-col",
                                      { key: index },
                                      [
                                        _c("ResultsCards", {
                                          staticClass: "mx-auto",
                                          attrs: {
                                            title: paqueteM.name,
                                            entradaId: paqueteM._id,
                                            showDefault: false,
                                            monumentoNombreURL: paqueteM.name,
                                            monument: `Lugar: ${paqueteM.monumentoId.nombre}`,
                                            precioOrientativo:
                                              paqueteM?.precioOrientativo
                                                ? _vm.$t(
                                                    "default.CARDS.START_FROM",
                                                    {
                                                      msg: _vm.getPrice(
                                                        _vm.visita
                                                          ?.precioOrientativo
                                                      ),
                                                    }
                                                  )
                                                : _vm.$t(
                                                    "default.CARDS.START_FROM",
                                                    {
                                                      msg: _vm.getPrice(
                                                        paqueteM?.content?.reduce(
                                                          (acc, cur) =>
                                                            acc +
                                                            Number(
                                                              cur.precioOrientativo
                                                            ),
                                                          0
                                                        )
                                                      ),
                                                    }
                                                  ),
                                            "image-url": `${_vm.imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`,
                                            showBuyButton: true,
                                            tipo: "paquetes_monumentos",
                                          },
                                          on: {
                                            goToEntrada: () =>
                                              _vm.goToMicrositioPaqueteMonumento(
                                                {
                                                  name: paqueteM.name,
                                                  id: paqueteM._id,
                                                }
                                              ),
                                            buy: () =>
                                              _vm.goToBuy(paqueteM, true),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm._l(
                                  _vm.paquetes.concat(_vm.servicios),
                                  function (product) {
                                    return _c(
                                      "mdb-col",
                                      { key: product._id },
                                      [
                                        _c("ResultsCards", {
                                          staticClass: "mx-auto",
                                          attrs: {
                                            title:
                                              product.nombres?.find(
                                                (item) =>
                                                  item.idioma ===
                                                  _vm.$i18n.locale
                                              )?.texto ??
                                              product.nombreServicio,
                                            showDefault: false,
                                            entradaId: product._id,
                                            monumentoNombreURL: product.nombre,
                                            text: `Lugar: ${product.place}`,
                                            monument: `Lugar: ${product.place}`,
                                            tipo: "paquetes_prestadores",
                                            precioOrientativo:
                                              product?.precioOrientativo
                                                ? _vm.$t(
                                                    "default.CARDS.START_FROM",
                                                    {
                                                      msg: _vm.getPrice(
                                                        product?.precioOrientativo
                                                      ),
                                                    }
                                                  )
                                                : "",
                                            "image-url": `${_vm.imagenesUrl}/${
                                              product.imageLabel
                                            }/${
                                              product.oldId
                                                ? product.oldId +
                                                  "/" +
                                                  encodeURI(product.banner)
                                                : product.prestadorId +
                                                  "/" +
                                                  product.banner
                                            }`,
                                            showBuyButton: true,
                                          },
                                          on: {
                                            goTo: function ($event) {
                                              return _vm.goToEntrada(product)
                                            },
                                            goToEntrada: () =>
                                              _vm.goToEntrada(product),
                                            buy: () => _vm.goToBuy(product),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "mdb-col",
            { staticClass: "order-3", attrs: { col: "12" } },
            [
              _c("h3", { staticClass: "spainhn-subtitle text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("default.HOME_NUESTRAS_RUTAS"))),
              ]),
              _c(
                "mdb-container",
                { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                [
                  _vm.rutas
                    ? _c(
                        "div",
                        { staticClass: "py-2" },
                        [
                          _c("hr", { staticClass: "m-0 mt-1 mb-2 mx-3" }),
                          _c(
                            "mdb-badge",
                            {
                              staticClass: "spainhn-header-info",
                              staticStyle: {
                                "background-color":
                                  "var(--spainhn-rojo-spainhn) !important",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.sugerencias
                                      ? _vm.$t(
                                          "default.HOME_RESULTS_NEAR_ROUTES_LABEL"
                                        )
                                      : _vm.$t(
                                          "default.HOME_RESULTS_ROUTES_LABEL"
                                        )
                                  ) +
                                  " "
                              ),
                              _c("font-awesome-icon", {
                                class: { "d-none": _vm.sugerencias },
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: { icon: "fa-location-dot" },
                              }),
                            ],
                            1
                          ),
                          !_vm.clorian
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap",
                                },
                                _vm._l(_vm.rutas, function (ruta, index) {
                                  return _c("Cards3", {
                                    key: index,
                                    attrs: {
                                      showSubtitle: false,
                                      title: ruta.nombres?.find(
                                        (item) =>
                                          item.idioma === _vm.$i18n.locale
                                      )?.texto,
                                      "image-url": `${_vm.imagenesUrl}/rutas/logo/${ruta?.rutaId}`,
                                    },
                                    on: { goTo: () => _vm.goToRoute(ruta._id) },
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "mdb-row",
                            {
                              staticClass: "mx-auto d-md-none",
                              class: { "card-container": !_vm.showAllRoutes },
                            },
                            _vm._l(_vm.rutas, function (ruta, index) {
                              return _c(
                                "mdb-col",
                                { key: index, staticClass: "px-0" },
                                [
                                  _c("CardsGeneral", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      title: ruta.nombres?.find(
                                        (item) =>
                                          item.idioma === _vm.$i18n.locale
                                      )?.texto,
                                      bold: true,
                                      muted: true,
                                      content: `${
                                        ruta.duracion
                                          ? ruta.duracion?.find(
                                              (item) =>
                                                item.idioma === _vm.$i18n.locale
                                            )?.texto
                                          : "1 día"
                                      }`,
                                      "image-url": `${_vm.imagenesUrl}/rutas/logo/${ruta.rutaId}`,
                                    },
                                    on: {
                                      goToUrl: () => _vm.goToRoute(ruta._id),
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "mdb-row",
                            {
                              staticClass: "mx-auto d-md-none",
                              class: { "d-none": _vm.rutas.length == 0 },
                            },
                            [
                              _c("mdb-col", { attrs: { col: "12" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "read-more-btn spainhn-gray-background mx-auto mb-1",
                                    on: { click: () => _vm.toggleFullRoutes() },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "default.HOME_RESULTS_MORE_ROUTES"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }