// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets//MONUMENTOS_.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.mobile-cards-container[data-v-72a1924e] {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    row-gap: 15px;\r\n    -moz-column-gap: 15px;\r\n         column-gap: 15px;\r\n    margin-bottom: 15px;\n}\n.disable-selector[data-v-72a1924e] {\r\n    color: rgba(225, 225, 225);\n}\n.image-header[data-v-72a1924e] {\r\n    position: absolute;\r\n    height: 25vh;\r\n    width: 100%;\r\n    background-position: center;\r\n    /* background-image: url('../assets//MONUMENTOS_.jpg'); */\r\n    background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(133, 11, 11, 0.85) 100%);\n}\n.image-header-container[data-v-72a1924e] {\r\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), lightgray 50% / cover repeat;\r\n    width: 100%;\r\n    height: 25vh;\n}\n.header-text[data-v-72a1924e] {\r\n    font-size: 14px;\r\n    color: #FFF;\r\n    font-weight: 400;\r\n    font-style: normal;\r\n    font-family: Montserrat;\r\n    max-height: 80%;\r\n    max-width: 85%;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\n}\n.text-container[data-v-72a1924e] {\r\n    position: relative;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100%;\r\n    -webkit-line-clamp: 4;\n}\n.header-title[data-v-72a1924e] {\r\n    color: #FFF;\r\n    text-align: center;\r\n    font-family: Montserrat;\r\n    font-size: 14px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    line-height: normal;\n}\n.read-more-btn[data-v-72a1924e] {\r\n    width: 85px;\r\n    height: 35px;\r\n    flex-shrink: 0;\r\n    border-radius: 20px;\r\n    background: var(--RUTA, #BF5B5B);\r\n    color: #FFF;\r\n    text-align: center;\r\n    font-family: 'Montserrat';\r\n    font-size: 14px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    line-height: normal;\r\n    border: none;\r\n    padding: 0;\n}\r\n", ""]);
// Exports
module.exports = exports;
